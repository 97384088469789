const tag = document.createElement('script');
tag.src = "https://www.youtube.com/iframe_api";

const firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

const buttons = document.querySelectorAll('[data-open-video]');
const closeButtons = document.querySelectorAll('[data-dismiss="modal"]');
const modalButtons = document.querySelectorAll('[data-target]');

for (var i = 0; i < buttons.length; i++) {
    buttons[i].addEventListener('click', playVideo)
}

for (var i = 0; i < modalButtons.length; i++) {
    modalButtons[i].addEventListener('click', startModalVideo)
}

function createPlayer(playerInfo, videoId) {
    if(!document.getElementById(playerInfo.id).hasChildNodes()) {
        return new YT.Player(playerInfo.id, {
            videoId: videoId,
            playerVars: { 'autoplay': 1,  'rel': 0, 'modestbranding': 1 },
            events: {
                'onReady': onPlayerReady
            }
        });
    }
}

function onPlayerReady(event) {
    event.target.m.addEventListener('click', function (event) {
        event.target.playVideo();
    })

    const close = document.getElementsByClassName('close');
    for(var i = 0; i < close.length; i++) {
        close[i].addEventListener('click', function () {
            event.target.stopVideo();
        })
    }
}

function playVideo(event) {
    event.target.parentNode.style.display = 'none';
    const buttonDataSetName = event.target.dataset.openVideo;
    const modal = document.querySelectorAll('[data-video-type="'+ buttonDataSetName +'"]');
    modal[0].parentNode.style.display = 'flex';
    createPlayer(modal[0], modal[0].dataset.videoId);
}

function startModalVideo(event) {
    const modal = document.querySelector(event.target.dataset.target);
    const button = modal.querySelector('[data-open-video]')
    button.click()
}