import Popper from 'popper.js/dist/umd/popper.js';

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

try {
    window.$ = window.jQuery = require('jquery');
    window.Popper = Popper;

    require('bootstrap');
    require('imagelightbox');
} catch (e) {
}
