$(document).ready(function() {
    $('#casinos-top-list').find('.toggle').on('click', function(e){
        e.preventDefault();
        if (!$(this).hasClass('opened') ) {
            $(this).find('.text').text( $(this).data('less') );
            $(this).find('.fa-caret-down').removeClass('d-block').addClass('d-none');
            $(this).find('.fa-caret-up').removeClass('d-none').addClass('d-block');
        } else {
            $(this).find('.text').text( $(this).data('more') );
            $(this).find('.fa-caret-up').removeClass('d-block').addClass('d-none');
            $(this).find('.fa-caret-down').removeClass('d-none').addClass('d-block');
        }
        $(this).toggleClass('opened');
        $(this).parent().find('.details').slideToggle();
    });
});