$(document).ready(function() {
    $('.icon', '.item-card-info-toggle').click(function () {
        let icon = $(this);

        if (icon.hasClass('icon-info')) {
            icon.addClass('d-none');
            icon.siblings('.icon-close').removeClass('d-none');
            icon.parent().siblings('.item-card-info').removeClass('d-none');
        } else {
            icon.addClass('d-none');
            icon.siblings('.icon-info').removeClass('d-none');
            icon.parent().siblings('.item-card-info').addClass('d-none');
        }
    });

    $('a.gallery-image')
        .imageLightbox({overlay: true});

    // addSticky($(window));
    $('.scroll-to').click(function (e) {
        e.preventDefault();
        const scrollTo = $(this).attr("href");
        $('html,body').animate({scrollTop: $(scrollTo).offset().top},'slow');
    })

    updateCsrf();

    setInterval(function(){ 
        updateCsrf();
    }, 300000);
});

function updateCsrf() {
    $.get('/csrf').done(function(data){
        $('[name="csrf-token"]').attr('content', data);
    });
}
