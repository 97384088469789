$(document).ready(function() {
    $('button.navbar-toggler', '#header-container').on('click', function(e){ 
        e.stopPropagation();
        
        var currentState = $(this).attr('data-hm'),
            togler = $(this),
            body = $('body');

        if (currentState == 'closed') {
            togler.attr('data-hm', 'opened');
            body.addClass('sidebar-opened');
        } else {
            togler.attr('data-hm', 'closed');
            body.removeClass('sidebar-opened');
        }         
    });

    $('.navbar-collapse', '#header-container').on('click', function(e) {
        e.stopPropagation();
    });
    
    $('.sb-close-button').on('click', function(){
        $('button.navbar-toggler', '#header-container').attr('data-hm', 'closed');
        $('body').removeClass('sidebar-opened');           
    });
    
    $('#header-wrapper, #content-wrapper, #footer-wrapper').on('click', function(){
        var currentState = $('button.navbar-toggler', '#header-container').attr('data-hm');
                    
        if (currentState == 'opened') {
            $('button.navbar-toggler', '#header-container').attr('data-hm', 'closed');
            $('body').removeClass('sidebar-opened');
        }           
    });
});