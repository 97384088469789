import moment from 'moment'
import numeral from 'numeral'

$(document).ready(function() {

    if (process.env.MIX_COUNT_JACKPOT !== 'true') {
        return;
    }

    const now = moment();

    $('.jackpot-counter', '#jackpots').each(function () {
        const counter = $(this),
            currentJackpot = parseFloat(counter.data('jackpot')),
            step = (parseFloat(counter.data('step')) / 60).toFixed(2);

        setInterval(function () {
            const diff = moment().diff(now, 'seconds');

            let jackpot = currentJackpot + (step * diff);
            counter.html(numeral(jackpot).format('0,0.00')+' €');
        }, 1000);
    });
});